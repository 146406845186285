import { AddCircleOutline, Key, ErrorOutline, Category, Edit } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from 'components';
import { getAllProviderCredsService, getProveedoresService, getProviderCredsService } from 'services/admin/providerService';
import EditIcon from '@mui/icons-material/Edit';
import { setCurrentProvider, setProviders, setProviderCreds, setProviderSelected, setJustCreds, setCurrentOperator, setCurrentSegment } from 'redux/slices/admin/providerSlice';
import { errorAlert } from 'helpers/alerts';
import { validatePermission } from 'helpers/authentication';
import { setProviderCredSelected } from 'redux/slices/admin/providerSlice';
import { Checkbox, FormControlLabel } from '@mui/material';

const ProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { justCreds, providers, providerCreds } = useSelector(store => store.providers)

  useEffect(() => {
    if (justCreds)
      getAllProviderCredsService().then(response => dispatch(setProviderCreds(response))).catch(error => errorAlert(error));
    else
      getProveedoresService().then(response => dispatch(setProviders(response))).catch(error => errorAlert(error));
    // eslint-disable-next-line
  }, [justCreds]);

  const handlerCreate = () => {
    navigate("crear");
  };

  const handlerProviderCreds = (row) => {
    (async () => {
      try {
        const response = await getProviderCredsService(row.host);
        dispatch(setProviderCreds(response));
        dispatch(setProviderSelected(row));
      } catch (error) {
        errorAlert(error);
      }
    })();
    navigate("creds");
  }

  const handlerEdit = (row) => {
    dispatch(setCurrentProvider(row));
    navigate("editar")
  };

  return (
    <TableData
      dataRows={justCreds ? providerCreds : providers}
      headerButtons={[
        <FormControlLabel
          label={'Ver solo credenciales'}
          control={
            <Checkbox
              checked={justCreds}
              onChange={(e) => {
                dispatch(setJustCreds(e.target.checked))
              }}
            />
          }
        />,
        validatePermission('proveedores', 'proveedores', ['agregar']) &&
        <CustomButton
          icon={<AddCircleOutline fontSize="large" />}
          key="addProvider"
          name={"Proveedor"}
          click={handlerCreate}
        />,
      ]}
      dataTitle={justCreds ? [
        "Id",
        "Proveedor",
        "Nombre",
        "Client Id",
        "Username",
        "Conectada",
        "Conciliación",
      ] : [
        "Id",
        "Nombre",
        "Tipo",
        "Host",
      ]}
      formatter={({
        identification,
        //provider
        container_name, type, host,
        //provider_creds
        conciliacion_service, name_connector, client_id, user_name, active, conciliacion
      }) => (justCreds ? [
        identification,
        conciliacion_service,
        name_connector,
        client_id,
        user_name,
        active ? "SI" : "NO",
        conciliacion ? "ACTIVA" : "INACTIVA"
      ] : [
        identification,
        container_name,
        type,
        host,
      ])}
      actions={justCreds ?
        [
          validatePermission('proveedores', 'productos', ['ver']) &&
          {
            label: "Productos",
            icon: <Category fontSize="medium" />,
            onClick: (data) => {
              dispatch(setProviderCredSelected(data));
              dispatch(setCurrentOperator(null));
              dispatch(setCurrentSegment(null));
              dispatch(setProviderCredSelected(data));
              navigate(data.provider?.type === 'RECHARGE' ? "creds/productos/tae" : "creds/productos/pds");
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <Edit fontSize="medium" />,
            onClick: (data) => {
              dispatch(setProviderCredSelected(data));
              navigate("creds/edit");
            },
          },
        ] : [
          validatePermission('proveedores', 'proveedores', ['editar']) &&
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Credenciales",
            icon: <Key fontSize="medium" />,
            onClick: (data) => {
              handlerProviderCreds(data);
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Error Codes",
            icon: <ErrorOutline fontSize="medium" />,
            onClick: (data) => {
              dispatch(setProviderCredSelected({ conciliacion_service: data.host }));
              navigate("creds/errorcodes");
            },
          },
          // validatePermission('proveedores', 'proveedores', ['eliminar']) &&
          // {
          //   label: "Eliminar",
          //   icon: <DeleteForeverIcon fontSize="medium" />,
          //   onClick: (data) => {
          //     handlerRemove(data);
          //   },
          // }
        ]}
    />
  )
}

export default ProviderScreen